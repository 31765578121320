<template>
  <div>
    <div class="title">过户单导出</div>

    <div class="block">
      <el-form ref="form" :model="form" size="small">
        <el-row>
          <el-col :span="5">
            <el-form-item label="仓库：" label-width="100px" prop="warehouseName">
              <el-input v-model="form.warehouseName" style="width: 160px;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="采购方：" label-width="100px" prop="buyerName">
              <el-input v-model="form.buyerName" style="width: 160px;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="过户日期：" label-width="100px" prop="name">
              <el-date-picker v-model="form.date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 240px;">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item>
              <div style="display: flex;">
                <div class="btn search" @click="search">搜索</div>
                <div class="btn reset" @click="reset">重置</div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="table">
      <el-table :data="tableData" :header-cell-style="{'text-align':'center','font-weight': 'normal','font-size': '12px', 'color': '#333'}" :cell-style="{'text-align':'center','font-weight': 'normal','font-size': '12px','color': '#333'}" style="width: 100%" size="mini">
        <el-table-column v-for="(item, index) in tableHeader" :key="index" :prop="item.prop" :label="item.label" :width="item.width">
          <template slot-scope="scope">
            <div v-if="item.prop !== 'operate'">{{scope.row[item.prop]}}</div>
            <div v-else>
              <el-button v-for="(item, index) in scope.row.operate" :key="index" type="text" size="mini" @click="operate(item.node_id, scope.row)">{{ item.name }}</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background layout="total, prev, pager, next" :total="total" @current-change="handleCurrentChange" style="margin-top: 10px;"></el-pagination>
    </div>

    <div class="export-btn" @click="exportFile">导出Excel</div>

  </div>
</template>

<script>
import axios from 'axios'
const http = axios.create({
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json'
  }
})
export default {
  data () {
    return {
      form: {
        warehouseName: '',
        buyerName: '',
        date: []
      },
      tableData: [],
      tableHeader: [],
      total: 0,

      currentPage: 0,
      pageSize: 10
    }
  },
  created () {
    this.search()
  },
  methods: {
    search () {
      let params = {
        process_id: '00000211', // 流程ID
        nodeInfo: // 传参  查询则是查询条件  可传可不传等
        {
          size: this.pageSize, // 分页页容量（从0开始，-1代表查全部）
          page: this.currentPage, // 分页页码（从0开始）
          transferOrderNumber: '', // 过户单号（可不传）
          warehouseName: this.form.warehouseName, // 仓库（可不传）
          buyerName: this.form.buyerName, // 买方名称
          beginBillingDate: this.form.date[0] || '', // 开单日期（起）
          endBillingDate: this.form.date[1] || '', // 开单日期（止）
          pageType: 'Sales'
        }
      }
      this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params).then((res) => {
        this.tableHeader = res.data.title
        this.tableData = res.data.content
        this.total = res.data.total
      }).catch((err) => {

      })
    },
    reset () {
      this.form = {
        warehouseName: '',
        buyerName: '',
        date: []
      }
      this.search()
    },
    exportFile () {
      let params = {
        process_id: '00000211',
        execution_type: 'get',							// get 代表读请求 获取数据,  set	代表写请求 编辑等操作
        nextProcessNode: '001',						// 列表接口的  button.NodeId_1字段的值
        nodeInfo: {
          size: -1,									// 分页页容量（从0开始，-1代表查全部） 可不传
          page: 0,									// 分页页码（从0开始）  可不传
          transferOrderNumber: '',					// 过户单号（可不传）
          warehouseName: this.form.warehouseName,							// 仓库（可不传）
          buyerName: this.form.buyerName,								// 买方名称
          beginBillingDate: this.form.date[0] || '',						// 开单日期（起）
          endBillingDate: this.form.date[1] || '',							// 开单日期（止）
          pageType: 'Sales',
          exportType: 'transferOrderExport'
        }
      }
      let p1 = new Promise((resolve, reject) => {
        this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params).then((res) => {
          resolve(res.data.id)
        }).catch((err) => {

        })
      })
      p1.then(res => {
        http.get('/file/getFilePath?fileId=' + res).then((result) => {
          const { data } = result.data
          let url = data['common_file@path']
          window.open(`/downfile${url}`, '_blank')
        }).catch((err) => {

        })
      })
    },
    handleCurrentChange (page) {
      this.currentPage = page - 1
      this.search()
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  border-bottom: 3px solid #1d74e0;
  height: 35px;
  line-height: 27px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #666666;
  padding-left: 30px;
}
.block {
  border: #e5e5e5 1px solid;
  padding: 10px 30px 0 30px;
  margin-top: 5px;
  .btn {
    background: #2d5171;
    color: #ffffff;
    font-size: 12px;
    margin-left: 10px;
    height: 30px;
    line-height: 30px;
    width: 80px;
    margin-top: 2px;
    text-align: center;
    cursor: pointer;
  }
}
.table {
  margin-top: 10px;
}
.export-btn {
  background: #c3214a;
  color: #fff;
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  width: 120px;
  margin: 30px auto;
  text-align: center;
  cursor: pointer;
}
</style>
